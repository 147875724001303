.App {
  text-align: center;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.height-available{
  height: -webkit-fill-available;
}

.list-circle{
  list-style: circle;
}









.custom_input {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

/* Sidebar */
.logoSidebar{
  width: 4vw;
  height: 4vw;
}

.buttonSidebar{
  width: 2.3vw;
  height: 2.3vw;
}

.BigLogoSidebar{
  height: 7vw;
  width: 17.5vw;
}

.logoFooter{
  width: 16.5vw;
  height: 7vw;
}

.iconFooter{
  width: 3vw;
  height: 3.1vw;
}

#topping img{
  object-fit: contain !important;
  height: auto !important;
}

#topping{
  padding-bottom: 5vw;
  height: fit-content !important;
}


/* Portrait and Landscape */
@media only screen
and (min-device-width: 768px)
and (max-device-width: 1024px)
and (-webkit-min-device-pixel-ratio: 1) {
  .logoSidebar{
    width: 6vw;
    height: 6vw;
  }
  
}


.card {
  flex: 0 0 auto; /* Prevent items from growing */
  width: 300px; /* Set your card width */
  margin-right: 20px; /* Adjust margin between cards */
  background-color: #f0f0f0; /* Placeholder background */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.card img {
  width: 100%; /* Ensure the image fills the card */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px 8px 0 0; /* Rounded corners only at the top */
}